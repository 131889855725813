<ng-container *transloco="let t; read: 'changelog'">
  <div class="changelog">
    <p class="pb-2">
      {{t('description', {installed: ''})}}
      <span class="badge bg-secondary">{{t('installed')}}</span>
      {{t('description-continued', {installed: ''})}}
    </p>
    <ng-container *ngFor="let update of updates; let indx = index;">
      <div class="card w-100 mb-2" style="width: 18rem;">
        <div class="card-body">
          <h4 class="card-title">{{update.updateTitle}}&nbsp;
            @if (update.isOnNightlyInRelease) {
              <span class="badge bg-secondary">{{t('nightly', {version: update.currentVersion})}}</span>
            } @else if (update.isReleaseEqual) {
              <span class="badge bg-secondary">{{t('installed')}}</span>
            } @else if (update.isReleaseNewer && indx === 0) {
              <span class="badge bg-secondary">{{t('available')}}</span>
            }
          </h4>
          <h6 class="card-subtitle mb-1 mt-1 text-muted">{{t('published-label')}}{{update.publishDate | date: 'short'}}</h6>


          <pre class="card-text update-body">
            <app-read-more [text]="update.updateBody" [maxLength]="500"></app-read-more>
          </pre>
          <a *ngIf="!update.isDocker && update.updateVersion === update.currentVersion" href="{{update.updateUrl}}" class="btn disabled btn-{{indx === 0 ? 'primary' : 'secondary'}} float-end" target="_blank" rel="noopener noreferrer">{{t('installed')}}</a>
          <a *ngIf="!update.isDocker && update.updateVersion !== update.currentVersion" href="{{update.updateUrl}}" class="btn btn-{{indx === 0 ? 'primary' : 'secondary'}} float-end" target="_blank" rel="noopener noreferrer">{{t('download')}}</a>
        </div>
      </div>
    </ng-container>
  </div>


  <app-loading [loading]="isLoading"></app-loading>

</ng-container>
